import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-pharmaceutical-impurities',
  templateUrl: './pharmaceutical-impurities.component.html',
  styleUrls: ['./pharmaceutical-impurities.component.scss']
})
export class PharmaceuticalImpuritiesComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
