<div class="container">
    <div class="row">
        <div class="col-11">
            <div class="sourcingpro">
                <h2>Sourcing and Procurement Services-Products:</h2>
                <ol>
                    <li>Novel building blocks</li>
                    <li>Fine Chemicals</li>
                </ol>
                <h4>Novel building blocks:</h4>
                <p>
                    We manufacture and sourcing of novel building blocks numerous suppliers.  We continuously update our virtual catalogue to include compounds that are only accessible from chemistry very recently invented by our partner Research centres.  The following groups of compounds are examples of those available from Reax chemicals today, but we can also access numerous scaffolds and structures that are not displayed on our website.  Please contact us via email if you would like to view our virtual catalogue. Our building blocks are available from 10 milligram to multi-gram scale.  We hold a number of compounds in stock but also undertake custom synthesis.  We can also discuss exclusivity options and can introduce customers to academic inventors whilst offering to support collaborative research. Reaxchemicals offering and sourcing of organic& inorganic fine chemicals backed by technological expertise, high-quality and high-purity manufacturing, and innovative application support. Our business is modelled around the changing customer demands and our ability to offer value-added innovative solutions to meet and even exceed these demands. We would welcome receiving your specific inquiries for products listed below, along with quantity desired, specifications and required delivery schedule, to enable us to quote the most competitive prices and favourable terms.
                </p>
            </div>
        </div>
    </div>
</div>

