
<div id="about" class="about-area ptb-100">
    <nav aria-label="breadcrumb" class="breadcrumb">
        <div class="container">
            <div class="row">
                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <div class="banner_heading"> Manufacturing</div>
                    <nav aria-label="breadcrumb">
                        <a routerLink="/home" style="color:white;">Home >> </a>
                        <a href="" style="color:white;"> Manufacturing</a>
                    </nav>
                </div>
            </div>
        </div>
    </nav>




    <!--<div class="container-fluid banner_image">
      <div class="container">
        <div class="row">
          <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <div class="banner_heading">API Intermediates</div>
            <nav aria-label="breadcrumb">
              <a href="/home"style="color:white;">Home >> </a>
              <a href=""style="color:white;">API Intermediates</a>
            </nav>
          </div>
        </div>
      </div>
    </div>-->

    <div class="container-fluid">
        <div class="container">

            <div class="row mt-5">
                <div class="col-lg-12">
                    <h2>Manufacturing</h2>
                </div>
            </div>

            <div class="row">
                <div class="col-lg-5 mt-3">
                    <p>
                        At Scikem, our commitment to <strong>innovation</strong> and precision is the driving force behind every product we develop. From conception to production, each item undergoes meticulous scrutiny to ensure it meets our exacting standards. Our state-of-the-art, GMP-certified facility serves as the cornerstone of our operations, where cutting-edge technology and rigorous quality control measures come together to deliver products of unparalleled quality.
                    </p>
                    <p>
                        We take pride in our ability to consistently deliver  <strong>high-quality</strong> products that exceed customer expectations. Our dedicated team of professionals is committed to upholding the  <strong>highest standards of excellence</strong> in every aspect of our manufacturing process. At Scikem, <strong>quality is not just a goal; it's a promise</strong> we uphold with unwavering dedication and attention to detail.
                    </p>
                </div>
                <div class="col-lg-7 mt-5">
                    <img src="assets/Scikem/manf_main.webp" />
                </div>
              
            </div>


        </div>
    </div>

  </div>
    
    <style>
        @media only screen and (max-width:600px) {
            .banner_image {
                background-image: url('assets/breadcrubg.jpg');
                background-attachment: initial;
                background-position: right;
                background-repeat: no-repeat;
                background-size: cover;
                margin-top: 2rem;
                height: 90px;
            }

                .banner_image a {
                    color: white;
                    margin-left: 0%;
                }
            img {
                margin-left: -9px;
            }
            .banner_heading {
                font-family: "Poppins";
                font-size: 13px;
                color: white;
                font-weight: 500;
                letter-spacing: 0.5px;
                text-transform: uppercase;
                padding: 10px;
                margin-top: 11px;
                margin-left:-9px;
            }

            .body {
                overflow-x: hidden;
            }
        }
    </style>
