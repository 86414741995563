
<br />
<br />
      <div class="container">
          <div class="row">
              <div class="col-11">
                  <div class="Apiinter">
                      <div class="section-title">
                          <h2>
                              <span>API Intermediates</span>
                          </h2>
                          </div>
                          <p>
                              Reax is a key API Intermediates manufacturer & supplier in the global marketplace. We work closely
                              with API manufacturers and custom formulators to ensure that the active pharmaceutical ingredients you
                              require are readily available. We are providing a broad range of quality pharmaceutical materials at
                              very competitive costs. Working with sophisticated technologies and API manufacturers, we ensure that
                              the intermediates you need are readily available. Our range meets high standard of quality, reliability
                              and effectiveness. Our active pharmaceutical ingredients are used widely by clients worldwide to
                              manufacture a variety of products like pharmaceuticals, food, cosmetics, and more. Contributing to the
                              daily life, the intermediates are pure, result oriented and cost effective in nature.
                          </p>
                      </div>
                  </div>
              </div>
</div>