import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-healthy-safety',
  templateUrl: './healthy-safety.component.html',
  styleUrls: ['./healthy-safety.component.scss']
})
export class HealthySafetyComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
