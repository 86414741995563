import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-coreservices',
  templateUrl: './coreservices.component.html',
  styleUrls: ['./coreservices.component.scss']
})
export class CoreservicesComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
