
<div class="container">
    <div class="row">
        <div class="col-11">
    
<div class="custom">
   <h2>Custom synthesis Products:</h2>
    <p>
        Reax chemicals is the premier custom chemical blending and manufacturing company for any size business. We have the capability to formulate and produce product at every stage of the process.  Our full-service approach includes sourcing raw materials, laboratory development, pilot runs, and commercialization of any chemical blending project. Whether it's blending a simple mix of ingredients or performing custom chemical synthesis, Reax is equipped to work side-by-side with your company to bring your project to fulfilment.
    </p>
</div>
</div>
</div>
</div>
