<div class="container-fluid banner_image">
    <div class="container">
        <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div class="banner_heading">
                    Infrastructure
                </div>
                <nav aria-label="breadcrumb crumb">
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item  Home padding_l_"><a routerLink="/">Home</a></li>
                        <li class="breadcrumb-item"><a>
Infrastructure</a></li>
                    </ol>
                </nav>
            </div>
        </div>
    </div>
</div>
<div class="container-fluid">
    <img src="../../../assets/reaxinfbanr.jpeg"/>

    
</div>

<br /><br />
<div class="container">
    <div class="row justify-content-center">


        <div class=" col-md-6 col-sm-6 col-xs-4 card cardd1">

            <div class="card-block">
                <img class="card-img-top" src="../../../assets/R&DLab.png" /><br />
                <h3 class="card-title">R&D Lab</h3>

                <p class="card-text">An R&D lab is any laboratory where research and development work is undertaken. Many industries employ R&D to innovate and create new products, technologies, and other advances in their field</p>
            </div>
        </div>

        
            <div class=" col-md-6 col-sm-6 col-xs-4 card cardd2">

                <div class="card-block">

                    <img class="card-img-top" src="../../../assets/kilolab.png" />
                    <h3 class="card-title">KiloLab</h3>

                    <p class="card-text">Reaction capability : 200 L , 100 L glass Reactors</p>
                    <p>Other facilities: vacuum powder Dryer , Nautch Filter , and centrifuge</p>
                </div>
            </div>
        </div>

    </div>
   
                
            
        
    


