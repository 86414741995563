import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-customsynthesisof-speciality-chemicals',
  templateUrl: './customsynthesisof-speciality-chemicals.component.html',
  styleUrls: ['./customsynthesisof-speciality-chemicals.component.scss']
})
export class CustomsynthesisofSpecialityChemicalsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
