import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { NgImageSliderModule } from 'ng-image-slider';
import { NgxCaptureModule } from 'ngx-capture';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { ToastModule } from 'primeng/toast';
import { GMapModule } from 'primeng/gmap'
import { FilterPipe } from './Services/filter.pipe';// -> imported filter pipe
import { StickyNavModule } from 'ng2-sticky-nav';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxSmartModalModule } from 'ngx-smart-modal';
import { NgxScrollTopModule } from 'ngx-scrolltop';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { CountUpModule } from 'ngx-countup';
import { NgxTypedJsModule } from 'ngx-typed-js';
import { AccordionModule } from "ngx-accordion";
import {TabsModule} from 'ngx-tabset';
import { TooltipModule } from 'ng2-tooltip-directive';
import { ParticlesModule } from 'ngx-particle';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms' 
export function HttpLoaderFactory(httpClient: HttpClient) {
    return new TranslateHttpLoader(httpClient, './assets/i18n/', '.json');
}
import { HashLocationStrategy, Location, LocationStrategy, PathLocationStrategy } from '@angular/common';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { PreloaderComponent } from './components/common/preloader/preloader.component';
import { HomeOneComponent } from './components/home-one/home-one.component';
import { NavbarComponent } from './components/common/navbar/navbar.component';
import { WelcomeComponent } from './components/common/welcome/welcome.component';
import { AboutComponent } from './components/common/about/about.component';
import { StrategyComponent } from './components/common/strategy/strategy.component';
import { WhoWeAreComponent } from './components/common/who-we-are/who-we-are.component';
import { CtaComponent } from './components/common/cta/cta.component';
import { SkillComponent } from './components/common/skill/skill.component';
import { FunfactsComponent } from './components/common/funfacts/funfacts.component';
import { TeamComponent } from './components/common/team/team.component';
import { HowWeWorkComponent } from './components/common/how-we-work/how-we-work.component';
import { ServicesComponent } from './components/common/services/services.component';
import { FooterComponent } from './components/common/footer/footer.component';
import { SubscribeComponent } from './components/common/subscribe/subscribe.component';
import { PartnerComponent } from './components/common/partner/partner.component';
import { BlogComponent } from './components/common/blog/blog.component';
import { FeedbackComponent } from './components/common/feedback/feedback.component';
import { FaqComponent } from './components/common/faq/faq.component';
import { PricingComponent } from './components/common/pricing/pricing.component';
import { WorkComponent } from './components/common/work/work.component';
import { WhyWeDifferentComponent } from './components/common/why-we-different/why-we-different.component';
import { ContactComponent } from './components/common/contact/contact.component';
import { HomeTwoComponent } from './components/home-two/home-two.component';
import { HomeThreeComponent } from './components/home-three/home-three.component';
import { HomeFourComponent } from './components/home-four/home-four.component';
import { HomeFiveComponent } from './components/home-five/home-five.component';
import { ServicesTwoComponent } from './components/common/services-two/services-two.component';
import { HomeSixComponent } from './components/home-six/home-six.component';
import { HomeSevenComponent } from './components/home-seven/home-seven.component';
import { HomeEightComponent } from './components/home-eight/home-eight.component';
import { HomeNineComponent } from './components/home-nine/home-nine.component';
import { HomeTenComponent } from './components/home-ten/home-ten.component';
import { HomeElevenComponent } from './components/home-eleven/home-eleven.component';
import { HomeTwelveComponent } from './components/home-twelve/home-twelve.component';
import { HomeThirteenComponent } from './components/home-thirteen/home-thirteen.component';
import { BlogDetailsComponent } from './components/blog-details/blog-details.component';
import { DemoSidebarComponent } from './components/common/demo-sidebar/demo-sidebar.component';
import { LoginComponent } from './login/login.component';
import { PagelayoutComponent } from './pagelayout/pagelayout.component';
import { HomeComponent } from './pagelayout/home/home.component';
import { PageHeaderComponent } from './pagelayout/page-header/page-header.component';
import { PageFooterComponent } from './pagelayout/page-footer/page-footer.component';

import { AboutUsComponent } from './pagelayout/about-us/about-us.component';
import { ContactUsComponent } from './pagelayout/contact-us/contact-us.component';
import { TestingComponent } from './components/common/testing/testing.component';
import { ToastrModule } from 'ngx-toastr';
import { ProductDetailsComponent } from './pagelayout/product-details/product-details.component'
import { ApiIntermediateKSMSComponent } from './pagelayout/api-intermediate-ksms/api-intermediate-ksms.component';
import { BuildingBlocksComponent } from './pagelayout/building-blocks/building-blocks.component';
import { AliphaticCompoundsComponent } from './pagelayout/aliphatic-compounds/aliphatic-compounds.component';
import { FineChemicalsComponent } from './pagelayout/fine-chemicals/fine-chemicals.component';
import { PyridineDerivativesComponent } from './pagelayout/pyridine-derivatives/pyridine-derivatives.component';
import { CareersComponent } from './pagelayout/careers/careers.component';
import { ClientsComponent } from './pagelayout/clients/clients.component';
import { FacilitiesComponent } from './pagelayout/facilities/facilities.component';
import { MedicinalorSyntheticChemistryServicesComponent } from './pagelayout/medicinalor-synthetic-chemistry-services/medicinalor-synthetic-chemistry-services.component';
import { ProcessRComponent } from './pagelayout/process-r/process-r.component';
import { APIIntermediatesComponent } from './pagelayout/apiintermediates/apiintermediates.component';
import { PharmaceuticalImpuritiesComponent } from './pagelayout/pharmaceutical-impurities/pharmaceutical-impurities.component';
import { CustomsynthesisofSpecialityChemicalsComponent } from './pagelayout/customsynthesisof-speciality-chemicals/customsynthesisof-speciality-chemicals.component';
import { SourcingandProcurementServicessComponent } from './pagelayout/sourcingand-procurement-servicess/sourcingand-procurement-servicess.component';
import { CapabilitiesComponent } from './pagelayout/capabilities/capabilities.component';
import { CoreservicesComponent } from './pagelayout/coreservices/coreservices.component';
import { ProductInfoComponent } from './pagelayout/product-info/product-info.component';
import { from } from 'rxjs';
import { HealthySafetyComponent } from './pagelayout/healthy-safety/healthy-safety.component';
import { VisionMissionComponent } from './pagelayout/vision-mission/vision-mission.component';
import { AdvancedwebPageComponent } from './pagelayout/advancedweb-page/advancedweb-page.component';
import { SitemapComponent } from './pagelayout/sitemap/sitemap.component'

@NgModule({
    declarations: [
        FilterPipe, // -> added filter pipe to use it inside the component
        AppComponent, MedicinalorSyntheticChemistryServicesComponent, ProcessRComponent, PharmaceuticalImpuritiesComponent,
        PreloaderComponent, CustomsynthesisofSpecialityChemicalsComponent, SourcingandProcurementServicessComponent, APIIntermediatesComponent,
      HomeOneComponent, CapabilitiesComponent, CoreservicesComponent, HealthySafetyComponent, VisionMissionComponent,
        NavbarComponent,
        WelcomeComponent,
        AboutComponent,
        StrategyComponent,
        WhoWeAreComponent,
        CtaComponent,
        SkillComponent,
      FunfactsComponent,
      AdvancedwebPageComponent,
        TeamComponent,
        HowWeWorkComponent,
        ServicesComponent,
        FooterComponent,
        SubscribeComponent,
        PartnerComponent,
        BlogComponent,
        FeedbackComponent,
        FaqComponent,
        PricingComponent,
        WorkComponent,
        WhyWeDifferentComponent,
        ContactComponent,
        HomeTwoComponent,
        HomeThreeComponent,
        HomeFourComponent,
        HomeFiveComponent,
        ServicesTwoComponent,
        HomeSixComponent,
        HomeSevenComponent,
        HomeEightComponent,
        HomeNineComponent,
        HomeTenComponent,
        HomeElevenComponent,
        HomeTwelveComponent,
        HomeThirteenComponent,
        BlogDetailsComponent,
        DemoSidebarComponent,
        LoginComponent,
        PagelayoutComponent,
        HomeComponent,
        PageHeaderComponent,
        PageFooterComponent,
       
        AboutUsComponent,
        ContactUsComponent,
        TestingComponent,
     
        ProductDetailsComponent,
        ApiIntermediateKSMSComponent,
        BuildingBlocksComponent,
        AliphaticCompoundsComponent,
        FineChemicalsComponent,
        PyridineDerivativesComponent,
        CareersComponent,
        ClientsComponent,
        FacilitiesComponent,
    CapabilitiesComponent,
    ProductInfoComponent,
     HealthySafetyComponent,
     VisionMissionComponent,
     SitemapComponent,

    ],
    imports: [GMapModule,
        NgxCaptureModule,  NgImageSliderModule,
        ReactiveFormsModule,//Add if needed 
        FormsModule,     //Add if needed
        AutoCompleteModule,
        ToastModule,
        BrowserModule,
        AppRoutingModule,
        StickyNavModule,
        BrowserAnimationsModule,
        NgxSmartModalModule.forRoot(),
        ToastrModule.forRoot(), 
        NgxScrollTopModule,
        CarouselModule,
        FormsModule,
        AccordionModule,
        CountUpModule,
        TabsModule,
        TooltipModule,
        NgxTypedJsModule,
        ParticlesModule,
        HttpClientModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        })
    ],
  providers: [{ provide: LocationStrategy, useClass: PathLocationStrategy }
        ],
    bootstrap: [AppComponent]
})
export class AppModule { }
