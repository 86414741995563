import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-api-intermediate-ksms',
  templateUrl: './api-intermediate-ksms.component.html',
  styleUrls: ['./api-intermediate-ksms.component.scss']
})
export class ApiIntermediateKSMSComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
