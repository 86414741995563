import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-pyridine-derivatives',
  templateUrl: './pyridine-derivatives.component.html',
  styleUrls: ['./pyridine-derivatives.component.scss']
})
export class PyridineDerivativesComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
