<div id="about" class="about-area ptb-100">
    <nav aria-label="breadcrumb" class="breadcrumb">
        <div class="container">
            <div class="row">
                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <div class="banner_heading">PROCESS DEVELOPMENT</div>
                    <nav aria-label="breadcrumb">
                        <a href="/home"style="color:white;">Home >> </a>
                        <a href=""style="color:white;">Process Development</a>
                    </nav>
                </div>
            </div>
        </div>
    </nav>

    <!--<div class="container-fluid banner_image">
      <div class="container">
        <div class="row">
          <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <div class="banner_heading">PROCESS DEVELOPMENT</div>
            <nav aria-label="breadcrumb">
              <a href="/home">Home >> </a>
              <a href="">Process Development</a>
            </nav>
          </div>
        </div>
      </div>
    </div>-->

    <div class="container-fluid content_image">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <br />
                    <p style="color:black">
                        <b style="color:black">
                            Our process development services are focused on the need to develop scalable and robust synthetic routes under a
                            tight timeline
                        </b>. We scale up our clients' chemistry and/or suggest, develop and optimize alternate synthetic routes. Scikem will provide you with the appropriate solution to meet your expectations on quality, quantity and regulatory needs within the relevant timeline requirements.
                    </p>
                    <p style="color:black">
                        Scikem route scouting and route comparison studies to come up with more efficient synthetic route(s) involving
                        reduced number of process steps, less expensive raw materials, safety aspects, etc. This is followed by route
                        familiarization studies to identify the most appropriate one considering the delivery timelines and costs involved.
                    </p>
                    <p style="color:black">
                        Our development work ranges from Lab to manufacturing scales and we provide all required analysis and documentation
                        for process validation.
                    </p><p style="color:black">
                        Scikem is the right partner for all your Process Development needs.
                    </p>
                </div>
            </div>


            <div class="row">
                <div class="div_heading" style="color:black">Our Process Development and Manufacturing Services include</div>
                <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12" style="padding-left:1.2rem;">

                    <div class="row" style="padding-top:2rem">
                        <div class="col-lg-1 col-md-1 col-sm-1 col-xs-1 arrow_icon">
                            <img src="../../../assets/img/new imgs/new_alt_logo1.png" style="width:80%;" />
                        </div>
                        <div class="col-lg-11 col-md-11 col-sm-12 col-xs-12">
                            <p class="p_text">
                                Optimization of existing synthetic routes

                            </p>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-1 col-md-1 col-sm-1 col-xs-1 arrow_icon">
                            <img src="../../../assets/img/new imgs/new_alt_logo1.png" style="width:80%;" />
                        </div>
                        <div class="col-lg-11 col-md-11 col-sm-12 col-xs-12">
                            <p class="p_text">
                                Establishment of<b> Robust, Scalable, Safe & Environment friendly processes</b>
                            </p>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-1 col-md-1 col-sm-1 col-xs-1 arrow_icon">
                            <img src="../../../assets/img/new imgs/new_alt_logo1.png" style="width:80%;" />
                        </div>
                        <div class="col-lg-11 col-md-11 col-sm-12 col-xs-12">
                            <p class="p_text">
                                Custom Synthesis from mg to multi-kilogram scale in batch or continuous process
                            </p>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-1 col-md-1 col-sm-1 col-xs-1 arrow_icon">
                            <img src="../../../assets/img/new imgs/new_alt_logo1.png" style="width:80%;" />
                        </div>
                        <div class="col-lg-11 col-md-11 col-sm-12 col-xs-12">
                            <p class="p_text">
                                Development of GMP friendly alternative synthetic routes
                            </p>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-1 col-md-1 col-sm-1 col-xs-1 arrow_icon">
                            <img src="../../../assets/img/new imgs/new_alt_logo1.png" style="width:80%;" />
                        </div>
                        <div class="col-lg-11 col-md-11 col-sm-12 col-xs-12">
                            <p class="p_text">
                                Development and optimization of purification and isolation methods
                            </p>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-1 col-md-1 col-sm-1 col-xs-1 arrow_icon">
                            <img src="../../../assets/img/new imgs/new_alt_logo1.png" style="width:80%;" />
                        </div>
                        <div class="col-lg-11 col-md-11 col-sm-12 col-xs-12">
                            <p class="p_text">
                                Cost Effective Route with Intellectual Property
                            </p>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-1 col-md-1 col-sm-1 col-xs-1 arrow_icon">
                            <img src="../../../assets/img/new imgs/new_alt_logo1.png" style="width:80%;" />
                        </div>
                        <div class="col-lg-11 col-md-11 col-sm-12 col-xs-12">
                            <p class="p_text">
                                <b>Technology Transfer</b>, Process Development Report & Documentation
                            </p>
                        </div>
                    </div>
                    <div class="div_style">
                        <h4>OUR KEY STEPS </h4>
                        <p>➣ Identification of lead</p>
                        <p>➣ Synthetic Route development</p>
                        <p>➣ Feasibility studies</p>
                        <p>➣ Process Optimization</p>
                        <p>➣ Scale-up Troubleshooting</p>
                        <p>➣ Pilot Plant batches</p>
                        <p>➣ Development of analytical methods and validation</p>
                        <p>➣ Transfer to Commercial Manufacturing</p>
                        <p>➣ Regulatory filings</p>


                    </div>
                    <br />  <br />
                </div>
                <div class="col-lg-6 col-md-4 col-sm-4">
                    <img src="../../../assets/img/new imgs/reactor1.jpg" class="img" style="width:100%;" />
                </div>
            </div>
        </div>

    </div>

</div>

    <style>
        @media only screen and (max-width:600px) {
            .banner_image {
                background-image: url('assets/breadcrubg.jpg');
                background-attachment: initial;
                background-position: right;
                background-repeat: no-repeat;
                background-size: cover;
                margin-top: 2rem;
                height: 90px;
            }

                .banner_image a {
                    color: white;
                    margin-left: 0%;
                    font-size: 10px;
                }

            .banner_heading {
                font-family: "Roboto Condensed";
                font-size: 18px;
                color: white;
                font-weight: 600;
                letter-spacing: 3.5px;
                text-transform: uppercase;
                padding: 10px;
                margin-top: 11px;
            }

            .body {
                overflow-x: hidden;
            }
        }
    </style>
