<div id="about" class="about-area ptb-100">
    <nav aria-label="breadcrumb" class="breadcrumb">
        <div class="container">
            <div class="row">
                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <div class="banner_heading">Vision & Mission </div>
                    <nav aria-label="breadcrumb">
                        <a routerLink="/home" style="color:white;">Home >></a>
                        <a href="" style="color:white;">Vision & Mission </a>
                    </nav>
                </div>
            </div>
        </div>
    </nav>




    <!--<div class="container-fluid banner_image">
      <div class="container">
        <div class="row">
          <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <div class="banner_heading">Vision & Mission With Core Values</div>
            <nav aria-label="breadcrumb">
              <a href="/home">Home >></a>
              <a href="">Vision & Mission With Core Values</a>
            </nav>
          </div>
        </div>
      </div>
    </div>-->



    <div class="container">
        <div class="row">
            <div class="col-lg-7">
                <p>
                    <strong> Vision:</strong>
                    "To lead the way in revolutionizing global accessibility to high-quality chemistry solutions, fostering innovation, sustainability, and empowerment."
                </p>
                <p>
                    <strong>Mission:</strong>
                    "Our mission is to make high-quality pharmaceuticals accessible to everyone worldwide at an affordable price. We are dedicated to ensuring that our innovative and effective chemistry reaches every hand, improving global health and well-being."
                </p>
                <p>
                    <strong>To Make Quality Chemistry to Reach Every Hand of The World at an Affordable Price.</strong>

                </p>
            </div>
            <div class="col-lg-5 mt-4">
                <img src="assets/Scikem/vision&mission1.webp" />
            </div>
        </div>
    </div>

    <div class="container">
        <div class="row">
            <div class="col-lg-12"><p><strong>At scikem, we value honesty , integrity and highly value customer and employee loyality.</strong></p></div>

        </div>
        <div class="row">
            <div class="col-lg-6 mt-3"><img src="assets/Scikem/values.webp" /></div>
            <div class="col-lg-6">
                <p>"At Scikem, <strong>integrity and honesty</strong> are not just words, but guiding principles that shape our every action and decision.</p>
                <p> We believe in conducting our business with the utmost transparency and <strong>ethical conduct</strong>, <strong>earning the trust </strong> and <strong>respect</strong> of all stakeholders.<br /> Furthermore, we deeply appreciate the loyalty shown by both our customers and employees, recognizing them as integral members of our Scikem family.</p>
                <p> By upholding these values and nurturing strong relationships, we are committed to fostering a culture of <strong> trust, reliability,</strong> and <strong> excellence </strong>in everything we do."</p>
            </div>
        </div>
    </div>


</div>

    <style>
        @media only screen and (max-width:600px) {
            .banner_image {
                background-image: url('assets/breadcrubg.jpg');
                background-attachment: initial;
                background-position: right;
                background-repeat: no-repeat;
                background-size: cover;
                margin-top: 2rem;
                height: 90px;
            }

                .banner_image a {
                    color: white;
                    margin-left: 0%;
                }

            .banner_heading {
                font-family: "Poppins";
                font-size: 12px;
                color: white;
                font-weight: 500;
                letter-spacing: 1.5px;
                text-transform: uppercase;
                padding: 10px;
                margin-top: 11px;
                margin-left: -10px;
            }

            .body {
                overflow-x: hidden;
            }
        }
    </style>





