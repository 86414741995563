<div class="container-fluid mobile_servies_1" style="background-image: url('../../../../assets/img/new imgs/banners/bg_123.jpg');">
    <div id="welcome" class="welcome-area pt-100 pb-70">
        <div class="container">
            <div class="section-title">

                <h2>Our Services</h2>

            </div>


            <div class="row">
                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <div class="row os-animation animated fadeInUp" data-os-animation="fadeInUp" data-os-animation-delay="0.3s" style="animation-delay: 0.3s;">

                    </div>
                </div>
            </div>
        </div>
        <div class="container pb-5">
            <div class="row">

                <div class="col-lg-6 card mobilecard">
                    <p style="text-transform: math-auto; ">
                        With its innovative technical expertise in Chemistry, SCIKEM offers Solutions and Services to its Customers, in the areas of contract Research, CMO Intermediates, Specialty Molecules, Amino Acids, API Impurities, API Intermediates and other Miscellaneous products from milligram scale to metric ton scale. we believe in keeping our customers happy and providing them with products at a very competent price. please feel free to keep in touch with us about any of the business related Requirement/Queries
                    </p>
                </div>
                <div class="col-lg-6">
                    <img src="assets/Scikem/manf_main.webp" alt="manf_main"/>
                </div>
            </div>
        </div>

        <div class="container">
            <div class="section-title">

                <h2>Vision & Mission</h2>

            </div>


            <div class="row">
                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <div class="row os-animation animated fadeInUp" data-os-animation="fadeInUp" data-os-animation-delay="0.3s" style="animation-delay: 0.3s;">

                    </div>
                </div>
            </div>
        </div>
        <div class="container pt-5 pb-5">
            <div class="row">
                <div class="col-lg-6">
                    <img src="../../../../assets/Scikem/vision&mission1.webp" alt="vision&mission1" style="width: 100%;border-radius: 7px 7px 7px 7px;"/>
                    <!--<img src="assets/Scikem/vmv.webp" style="width: 100%;" />-->
                </div>
                <div class="col-lg-6 mobilecard card">
                    <p>
                  
                        <b>Vision :</b> "To lead the way in revolutionizing global accessibility to high-quality chemistry solutions, fostering innovation, sustainability, and empowerment."
                    </p>
                    <p>
                        
                        <b>Mission :</b> "At our core, we're committed to democratizing access to premium chemistry resources worldwide, driving progress, inclusivity, and positive impact for all."
</p>
                </div>

            </div>
        </div>


    </div>
    </div>
 


 

<div class="shape2"><img src="assets/img/shape1.png" alt="image"></div>


