import { Component, OnInit } from '@angular/core';
import { GeneralService } from '../../../Services/generalservice.service';
import { CartService } from '../../../Services/cart.service';

import { HttpClient, HttpHandler, HttpResponse, HttpRequest, HttpHeaders } from '@angular/common/http';


import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
  selectedItem: any = 'Home';
  ProductsSpecifications: any[];
  items: any[];
  FilterSpecData: any;
  filteredSpecifications: any[];
  SpecID: any;
  SpecName: any;
  Productsdata: any;
  ChemicalName: any;
  searchcomp: any;
  selectedcompanys: any;

  cartItemsCount: any;
  searchprods: any;
  selectedProduct: any;
  CategoryName: any;
    Cas: any; token: any;
    HomeURL: any;
    loginDet: any;
    loginDetails: any;
    arr: any;
    Categories: any;

    constructor(public generalService: GeneralService, public http: HttpClient, public router: Router, public CartService: CartService, public route: ActivatedRoute,) {
    this.CategoryName = this.route.snapshot.params['CategoryName'];
    this.ChemicalName = `${this.route.snapshot.params['ChemicalName']}-${this.route.snapshot.params['Cas']}`;
    this.ChemicalName = localStorage.getItem('ChemicalName')
    this.CategoryName = localStorage.getItem('CategoryName')
        this.Cas = localStorage.getItem('Cas')

        this.http.get('../assets/WebService.json').subscribe((data: any) => {
            this.HomeURL = data.Webservice;
        });
        this.loginDet = localStorage.getItem("LoginDetails");
        this.loginDetails = JSON.parse(this.loginDet)
  }
  

  ngOnInit() {
    this.CartService.getProducts().subscribe(res => {/*getting cart product list count from cartservice*/
      this.cartItemsCount = res.length

    })

  }

  locationreload() {
    window.location.reload();
  }
  countReturn() {
    this.CartService.getProducts().subscribe(res => {/*getting cart product list count from cartservice*/

      this.cartItemsCount = res.length
      return this.cartItemsCount
    })
    return this.cartItemsCount
  }
  clearValue() {
    this.Productsdata = "";
  }
  GetSpecdata(filteredSpecifications) {
    debugger
    this.Productsdata;
    localStorage.removeItem('Cattegoryid');
    localStorage.removeItem('CatlogID');
    localStorage.setItem("CatlogID", filteredSpecifications.Catalog_No)
    var route = this.router.url
    this.Productsdata = '';
    this.ChemicalName = '';
    if (route == '/products-info') {
      localStorage.setItem("Cattegoryid", filteredSpecifications.CategoryID);
      window.location.reload();
    }
    else {
      localStorage.setItem("Cattegoryid", filteredSpecifications.CategoryID);
      this.router.navigateByUrl('/products-info');


    }

  }
  filterSpecifications(event) {
    debugger
    //in a real application, make a request to a remote url with the query and return filtered results, for demo we filter at client side
    let filtered: any[] = [];
    let query = event.query;

    var UploadFile = new FormData();
    UploadFile.append("name", query);
      var url = "/api/SCIKEM/GettingsearchProductCatlogandcasandchemicalname";
    debugger
    this.generalService.PostData(url, UploadFile).then((data: any) => {
      debugger
      this.filteredSpecifications = [];
      this.filteredSpecifications = data

    }, err => {
      // this.generalService.ShowAlert('ERROR', 'Something went wrong. Please try again later.', 'error')
    });


  }


  filterProducts(event) {
    debugger
    let query = event.query;
    if (event.query.length > 2) {
      var UploadFile = new FormData()
      UploadFile.append("Param", query)
        var url = "api/SCIKEM/Search_Products"
      this.generalService.PostData(url, UploadFile).then(data => {
        debugger
        this.searchprods = data
      }, err => {
      });
    }
    }

    GetCategories() {

        var url = "api/Token/Gettoken";
        this.generalService.GetData(url).then((data: any) => {

            if (data && data.access_token) {
                this.token = data.access_token;
            }
            this.arr = [];
            this.arr.push({

            });

            var UploadFile = new FormData();
            UploadFile.append("Param", JSON.stringify(this.arr));
            UploadFile.append("Flag", '4');

            var url = this.HomeURL + "api/SCIKEM/Categories_CRUD";
            var accessToken = this.token; // Use the token obtained from Gettoken

            // Set the Authorization header with the access token
            const headers = new HttpHeaders({
                'Authorization': `Bearer ${accessToken}`// Prefix "Bearer" is a common convention for JWT tokens
            });

            // Use HttpHeaders in the request
            this.http.post(url, UploadFile, { headers }).subscribe(
                (data: any) => {

                    this.Categories = data;

                },
                (err) => {
                    this.generalService.ShowAlert('ERROR', 'Something went wrong, please try again later', 'error');
                });
        });
    }



    navigatingToSearchedProducts(CategoryID: number, CategoryName: any) {

        this.router.navigate(['/Products'], { queryParams: { categoryID: CategoryID, CategoryName: CategoryName } });
        // const queryParams = this.route.snapshot.queryParams;
        localStorage.setItem("cat", CategoryName);

    }

  //navigatingToSearchedProduct(data) {
  //  debugger
  //  console.log(data);
  //  var prodName = ""; var prodarr = []
  //  prodarr.push(data)
  //  var str = data.Catalog_No.split(" ")//spliting the category name into string array where space occured
  //  for (let i = 0; i < str.length; i++) {
  //    prodName += "_" + str[i];
  //  }
  //  this.generalService.setProducts(prodarr)
  //  this.router.navigate(['/Products' + prodName]);
  //}

  navigatingToSearchedProduct(data) {
    debugger;
    console.log(data);

    // Assuming data contains properties like Catalog_No, Category_Name, and Chemical_Name
    var catalogNo = data.Catalog_No;
    var CategoryName = data.CategoryName;
    var ChemicalName = data.ChemicalName;
    var Cas = data.Cas;
    

    // Combine category name and chemical name with a forward slash
    //var prodName = categoryName + "/" + chemicalName;

    // Decode the entire prodName
   

   

    // Set up the array with the product data
    var prodarr = [];
    prodarr.push(data);

    // Set the products using the service
    this.generalService.setProducts(prodarr);

    // Navigate to the desired URL path
    this.router.navigate(['/Products', CategoryName, ChemicalName + '-' + Cas]);
    localStorage.setItem("CategoryName", CategoryName);
    localStorage.setItem("ChemicalName", ChemicalName);
    localStorage.setItem("Cas", Cas);

    const url = 'https://Scikem.com/Products/' + CategoryName + '/' + ChemicalName + '-' + Cas;
    //window.open(url, '_blank',);

    }

   


  FilterList(id) {
    debugger

    localStorage.removeItem('catid');
    localStorage.setItem("catid", id);
    var route = this.router.url
    if (route == '/Products') {
      window.location.reload();
    }
    else {
      this.router.navigateByUrl('/Products');

    }

  }
  classApplied = false;
  toggleClass() {
    this.classApplied = !this.classApplied;
  }
  listClick(val) {
    this.selectedItem = val;
    }

    isActive(route: string): boolean {
        return this.router.isActive(route, true);
    }

}
