import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sourcingand-procurement-servicess',
  templateUrl: './sourcingand-procurement-servicess.component.html',
  styleUrls: ['./sourcingand-procurement-servicess.component.scss']
})
export class SourcingandProcurementServicessComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
