<footer class="footer-area footer_img">
    <div class="container">
        <div class="row">
            <div class="col-lg-3">
                <h3 class="scikem">Scikem</h3>
                <small>PHARMACEUTICALS PVT. LTD.</small>
                <!--<img src="assets/Scikem/logo.webp" />-->
                <div class="mt-3" style="color:white;">
                    "Feel free to reach out to Scikem, an emerging pharmaceutical company dedicated to innovation, research, and technology. Based in Hyderabad, India's bulk drug capital, we prioritize excellence to drive healthcare advancements."
                </div>
                <br /> <br />
            </div>
            <div class="col-lg-3">
                <h3>Our LINKS</h3>
                <p class="pb"><a href="/" (click)="onClick('home')" style="  color:white;">Home</a></p>
                <p class="pb"><a href="/about-us" style="cursor:default;">About US</a></p>
                <p class="pb ml-2"><a href="/about-us" style="color: #d1c5c5; font-size: 13px;">&#9733; Company Profile</a></p>
                <p class="pb ml-2"><a href="/vision-mission" style=" color: #d1c5c5; font-size: 13px;" >&#9733; Vision & Mission</a></p>
                <!--<p class="pb ml-2"><a href="/healthy-safety" style=" color: #d1c5c5; font-size: 13px;">&#9733; Leadership Team </a></p>-->
                <p class="pb"><a href="/Products" style="cursor: default">Products</a></p>
                <!--<p class="pb ml-2"><a href="/Products" style=" color: #d1c5c5; font-size: 13px;">&#9733; API Intermediates Products</a></p>-->
                <p class="pb p"><a href="/FineChemicals">Services</a></p>
                <p class="pb p"><a href="/MedicinalorSyntheticChemistryServices">Innovative Research</a></p>
                <p class="pb p"><a href="/APIIntermediates">Manufacturing</a></p>
                <p class="pb p"><a href="/ContactUs">Contact Now</a></p>
            </div>
            <div class="col-lg-2 mt-5 our">
                <p class="pb ourlinls"><a href="/FineChemicals">Services</a></p>
                <p class="pb ourlinls"><a href="/MedicinalorSyntheticChemistryServices">Innovative Research</a></p>
                <p class="pb ourlinls"><a href="/APIIntermediates">Manufacturing</a></p>
                <p class="pb ourlinls"><a href="/ContactUs">Contact Now</a></p>
            </div>
            <div class="col-lg-4 mobile">
                <a href="/ContactUs">
                    <h3>Contact Us</h3>
                </a>
                <address>
                    SCIKEM&nbsp;PHARMACEUTICALS&nbsp;PVT.&nbsp;LTD.<br />
                    <strong><i class="fa fa-phone"></i></strong>&nbsp;+91 9959455992<br />
                    <strong><i class="fa fa-envelope"></i></strong>&nbsp; <a href="mailto:rajireddy.akiti@scikem.com" target="_blank">
                        rajireddy.akiti@scikem.com
                    </a>
                    <br />
                </address>
 
                <ul class="social_links">
                    <li><a href="https://www.facebook.com/YourPageURL"><i class=" fa-brands fa-facebook icon_"></i> </a></li>
                    <li><a href="#"><i class="fa-brands fa-twitter icon_"></i></a></li>
                    <li><a href="#"><i class="fa-brands fa-instagram icon_"></i></a></li>
                    <li><a href="#"><i class="fa-brands fa-linkedin-in icon_"></i></a></li>
                </ul>



            </div>


        </div>


         
            <div class="row">
                <div class="col-lg-6 col-12 mt-3 footer_l">
                    <div><i class="fa-regular fa-copyright "></i>Scikem All rights reserved</div>
                </div>

                <div class="col-lg-6 col-6 mt-3 footer_r">
                    <span class="powered">Powered by <a href="https://gagri.net/" target="_blank"><img class="gagri2" src="../../../../assets/Scikem/GagriNewLogoColor2.webp" alt="Gagri" /></a></span>
                </div>
            </div>
       

        <!--<div class="container col_1">
      <ul class="display">
        <ng-container *ngFor="let item of searchcompany; let i = index">
          <li class="line_1" *ngIf="showAll || i < 10">
            <h5 style="font-size:9px;" [class.truncate-text]="!showAll && i >= 10"><span (click)="ProductInfo(item.Catalog_No)">{{item.ChemicalName}}</span></h5>
          </li>
        </ng-container>
      </ul>
      <span  *ngIf="!showAll && searchcompany.length > 10" (click)="showAll = true"><u style="cursor: pointer;">Read more <img src="../../../../assets/img/new imgs/rightarrow.png" alt="Readmore" style="width:20px;" /></u></span>
      <span  *ngIf="showAll" (click)="showAll = false"><u style="cursor: pointer;">Show less <img src="../../../../assets/img/new imgs/rightarrow.png" style="width:20px;" alt="rightarrow" /></u></span>
    </div>-->



    </div>






</footer>

<!--<app-demo-sidebar></app-demo-sidebar>-->

<ngx-scrolltop></ngx-scrolltop>


