
<div id="about" class="about-area ptb-100">
   
    <nav aria-label="breadcrumb" class="breadcrumb">
        <div class="container">
            <div class="row">
                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <div class="banner_heading">Products</div>
                    <nav aria-label="breadcrumb">
                        <a routerLink="/home" style="color:white;">Home>></a>
                        <a class="breadcrumb-item" href="/Products" style="color:white;">Products>></a>
                        <a *ngIf="categoryId==1" aria-current="page" style="color:white;">API Intermediates</a>
                    </nav>
                </div>
            </div>
        </div>
    </nav>

    <br />
    <div class="container mobileview ">
        <div class="row ">
            <div class="col-lg-12  mb-2">
                <strong>  Scikem </strong>is a key <b>
                    API Intermediates manufacturer & supplier in the global
                    marketplace
                </b>. We work closely with API manufacturers and custom formulators to ensure that the
                active pharmaceutical ingredients.We are providing a broad range of<b>
                    quality pharmaceutical
                    materials
                </b> at very competitive costs.<p></p>
            </div>
            <br />
            <marquee class="mb-2">&#9889;&#9889;  The patent should be verified and liability lies with the buyer.&#9889;&#9889;</marquee>
            <br />


            <!--<div class="col-lg-3 col-12">
        <div class="api"[class.active]="categoryId ==1" href="#p1" (click)="FilterList(1)">
            API Intermediates
        </div>
    </div>-->

            <div class="col-lg-3">
                <div class="vertical-pills">
                    <ul class="nav nav-pills flex-column">
                        <li class="nav-item" *ngFor="let item of Categories">
                            <!-- Display the category name -->
                            <a class="nav-link"
                               [routerLink]="['/Products']"
                               [class.active]="activeCategoryID == item.CategoryID "
                               [queryParams]="{ categoryID: item.CategoryID }" (click)="setActiveCategory(item.CategoryID)">
                                {{ item.CategoryName }} <!-- Category Name -->
                                <!-- Display the count of filtered products for this category -->
                                <span *ngIf="getFilteredProductCount(item.CategoryID) !== 0"></span>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>


            <div class="col-lg-9 col-12">

                <div class="row">
                    <div class="col-lg-5"></div>
                    <div class="col-lg-2 col-3">
                        <span class="countt"> {{searchcompany.length}} of  {{searchcompany.length}}</span>
                    </div>
                    <div class="col-lg-5 col-9">

                        <i class="fa fa-search "></i>&nbsp;
                        <p-autoComplete placeholder=" Search with your products....." [(ngModel)]="selectedcompanys" [suggestions]="searchcomp" (completeMethod)="filterTests()"
                                        field="ChemicalName" [dropdown]="false" (onClear)="clearItem($event)">
                        </p-autoComplete>
                    </div>
                </div>



            </div>
            <!--<div class="col-lg-4">
        <span class="count"><b> Products: {{searchcompany.length}}</b></span>
    </div>-->
        </div>
    </div>

    <br />
    
    <div class="container">
        <div class="row">
           
            <div class="col-lg-3">
                <img src="../../../assets/Scikem/qr-code.webp" />
                

                <!--<p>
                    Disclaimer :
                    Products covered by valid and unexpired patents not offered or supplied for commercial scale.
                    </p>
                <p>
                    The patent should be verified and liability lies with the buyer.
                    </p>
                <p>
                    Products coveded by Patents only for R&D use only.
                </p>-->
            </div>
            <div class="col-lg-9">
                <div class="row">
                    <div class="col-lg-3 col-xs-6 border_product" *ngFor="let item of searchcompany"style="cursor:pointer">
                        <div (click)="ProductInfo(item.CategoryName,item.ChemicalName,item.Cas)" class="border_product1" style="cursor:pointer;padding:10px">
                            <div class="row">
                                <div class="col-lg-12">
                                    <div class="image-container">
                                        <div *ngIf="item.Structure != null || item.Structure != ''">
                                            <img src="{{ HomeUrl + item.Structure }}" alt="Scikem" *ngIf="item.Structure" class="product_img mt-5 mb-5">
                                        </div>
                                        <div *ngIf="item.Structure == null || item.Structure == ''" class="product_img mt-5 mb-5">
                                            <img src="../../../assets/alt_logo-.png" alt="Scikem">
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-lg-12">
                                    <div class="text_size"> {{item.Catalog_No}}</div>
                                </div>
                                <br />
                                <div class="col-lg-12">
                                    <div class="text_size"> {{item.Cas}}</div>
                                </div>
                                <br />
                                <div class="col-lg-12">
                                    <div class="ChemicalName">

                                        {{item.ChemicalName}}
                                    </div>
                                </div>
                                <div class="col-lg-6"></div>
                                <div class="col-lg-6">
                                    <div class="details_btn">Details</div>
                                </div>
                            </div>
                        </div>
                        <br />
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>

<style>
    .ChemicalName {
        white-space: normal; /* Allow text to wrap */
    }
    .card {
        display: flex;
        flex-wrap: wrap;
    }

        .card .col-lg-12 {
            flex: 0 0 100%; /* Each column takes full width */
            padding: 0; /* Remove padding for consistent spacing */
        }

    .details_btn {
        margin-top: 10px; /* Adjust margin as needed */
    }
</style>

