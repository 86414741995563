<div id="pricing" class="pricing-area pt-100 pb-70 bg-F7F5F4">
    <div class="container">
        <div class="section-title">
            <h2>Our Products <span>Pricing</span></h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
        </div>
        <div class="row justify-content-center">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="pricingTable">
                    <h3 class="title">Basic</h3>
                    <div class="price-value">
                        <span class="currency">$</span>
                        <span class="amount">39.00</span>
                        <span class="month">/Per month</span>
                    </div>
                    <ul class="pricing-content">
                        <li><i class="fas fa-check"></i> Any Ware Access</li>
                        <li><i class="fas fa-check"></i> 10 GB Hosting</li>
                        <li><i class="fas fa-check"></i> 2 Unique Users</li>
                        <li><i class="fas fa-check"></i> 12 GB Capacity</li>
                        <li><i class="fas fa-check"></i> Weekly Backups</li>
                        <li><i class="fas fa-check"></i> Support 24 / hour</li>
                    </ul>
                    <a routerLink="/" class="btn btn-primary">Buy Now</a>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="pricingTable">
                    <h3 class="title">Standard</h3>
                    <div class="price-value">
                        <span class="currency">$</span>
                        <span class="amount">49.00</span>
                        <span class="month">/Per month</span>
                    </div>
                    <ul class="pricing-content">
                        <li><i class="fas fa-check"></i> Any Ware Access</li>
                        <li><i class="fas fa-check"></i> 15 GB Hosting</li>
                        <li><i class="fas fa-check"></i> 5 Unique Users</li>
                        <li><i class="fas fa-check"></i> 17 GB Capacity</li>
                        <li><i class="fas fa-check"></i> Weekly Backups</li>
                        <li><i class="fas fa-check"></i> Support 24 / hour</li>
                    </ul>
                    <a routerLink="/" class="btn btn-primary">Buy Now</a>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="pricingTable">
                    <h3 class="title">Premium</h3>
                    <div class="price-value">
                        <span class="currency">$</span>
                        <span class="amount">59.00</span>
                        <span class="month">/Per month</span>
                    </div>
                    <ul class="pricing-content">
                        <li><i class="fas fa-check"></i> Any Ware Access</li>
                        <li><i class="fas fa-check"></i> 20 GB Hosting</li>
                        <li><i class="fas fa-check"></i> 10 Unique Users</li>
                        <li><i class="fas fa-check"></i> 21 GB Capacity</li>
                        <li><i class="fas fa-check"></i> Weekly Backups</li>
                        <li><i class="fas fa-check"></i> Support 24 / hour</li>
                    </ul>
                    <a routerLink="/" class="btn btn-primary">Buy Now</a>
                </div>
            </div>
        </div>
    </div>
    <div class="shape1"><img src="assets/img/shape1.png" alt="image"></div>
</div>
