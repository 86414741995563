import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-aliphatic-compounds',
  templateUrl: './aliphatic-compounds.component.html',
  styleUrls: ['./aliphatic-compounds.component.scss']
})
export class AliphaticCompoundsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
